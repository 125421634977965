.overlay {
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  padding: 85px 20px 20px 20px;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px) saturate(150%);
  font-family: var(--parcours-content-font);
}
.dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 700px;
  max-height: 90%;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--parcours-grey-000);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}
.close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -10px;
  cursor: pointer;
  color: white;
  background: var(--parcours-grey-600);
  z-index: 3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.close > svg {
  color: white;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--parcours-grey-200);
  padding: 15px 0;
  gap: 5px;
}
.imageOuter {
  margin-top: -85px;
  border-radius: 8px;
  border: 2px solid var(--parcours-grey-200);
  background-color: var(--parcours-grey-000);
  width: 150px;
  flex: 0 0 115px;
}
.imageOuter > img {
  border-radius: 8px;
  width: 150px;
  height: 115px;
}
.bar {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
}
.prev,
.next {
  display: flex;
  flex: 1 1 100%;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  color: var(--parcours-grey-500);
}
.prev:hover,
.next:hover {
  color: var(--parcours-red);
}
.prev {
  justify-content: flex-start;
  margin-right: auto;
}
.next {
  justify-content: flex-end;
  margin-left: auto;
}
.name {
  font-weight: 700;
  font-size: 30px;
  color: var(--parcours-grey-600);
  white-space: nowrap;
}
.univers {
  color: var(--parcours-red);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}
.content {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  font-size: 14px;
}

.content > iframe {
  width: 100%;
  aspect-ratio: 16/9;
}
.content :global(.level_catchphrase) {
  font-weight: bold;
  padding-bottom: 10px;
}
.content p {
  margin: 5px 0;
}
.content i {
  font-size: 11px;
}
.content b {
  display: inline-block;
  padding-top: 10px;
}
.content em {
  display: inline-block;
  padding-top: 10px;
  font-style: normal;
  font-weight: bold;
}
.content li > em {
  display: inline;
  font-style: italic;
  font-weight: normal;
}
.content ul {
  padding: 0;
}
.content li {
  background: url(level-bullet.svg) no-repeat 0 2px;
  list-style-type: none;
  line-height: 1.4;
  padding: 0 0 10px 25px;
  margin: 0;
}

@media (max-width: 400px) {
  .overlay {
    padding: 0;
  }
  .imageOuter {
    margin-top: 0;
  }
  .dialog {
    padding: 0;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
  }
  .close {
    top: 10px;
    right: 10px;
    border-radius: 0;
  }
  .content {
    width: auto;
    padding: 0 20px;
  }
  .prev > span,
  .next > span {
    display: none;
  }
  .prev,
  .next {
    flex: 0 0 24px;
  }
}
