.moniteur {
  position: absolute;
}

.dot {
  width: 34px;
  height: 34px;
  background-color: var(--parcours-red);
  border: 4px solid var(--parcours-grey-600);
  border-radius: 50%;
}

.esfAcademy {
  position: absolute;
  bottom: calc(100% - 5px);
  left: calc(100% + 90px);
  width: 100px;
  max-width: none;
  height: 100px;
}

.challengeMoniteurs {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100px;
  max-width: none;
  height: 100px;
}

.name {
  position: absolute;
  right: -80px;
  bottom: 100%;
  white-space: nowrap;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.picture {
  position: absolute;
  top: 15px;
  left: 0;
  width: 250px;
  max-width: none;
  height: 250px;
}
