.universe {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.reversed {
  flex-direction: column-reverse;
}

.name {
  max-width: 240px;
  padding: 20px;
  color: var(--parcours-red);
  font-size: 30px;
  font-family: var(--parcours-title-font);
  font-weight: 700;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px) saturate(180%);
  border-radius: 20px;
  overflow: hidden;
}

.middle {
  display: flex;
  flex-direction: column-reverse;
}

.reversed .middle {
  flex-direction: column;
}

.dot {
  flex: 0 0 70px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 0 10px 0;
}

.reversed .dot {
  flex-direction: column;
  margin: 10px 0 0 0;
}

.dot::before {
  content: "";
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  background-color: var(--parcours-red);
  border: 3px solid var(--parcours-grey-600);
  border-radius: 50%;
}

.dot::after {
  content: "";
  width: 0;
  flex: 1 1 100%;
  border-left: 3px dashed var(--parcours-grey-600);
}

.pictureContainer {
  position: relative;
  flex: 0 0 160px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: var(--parcours-grey-000);
  border: 6px solid var(--parcours-red);
  transition: box-shadow 360ms var(--parcours-transition-easing);
}

.age {
  position: absolute;
  top: calc(100% - 40px);
  left: calc(100% - 40px);
  width: 100px;
  height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  font-size: 17px;
  color: var(--parcours-grey-000);
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  background-color: var(--parcours-red);
  z-index: -1;
}

.reversed .age {
  top: unset;
  bottom: calc(100% - 40px);
  left: calc(100% - 40px);
}

.picture {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.levels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--parcours-grey-100);
  border-radius: var(--parcours-radius-100);
}

.baseline {
  align-self: stretch;
  padding: 6px 10px;
  font-size: 20px;
  color: var(--parcours-grey-000);
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  background-color: var(--parcours-red);
  border-radius: var(--parcours-radius-100) var(--parcours-radius-100) 0 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 10px;
  padding: 10px 10px 10px 20px;
}

.level {
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  transition: color 80ms var(--parcours-transition-easing),
    text-shadow 240ms var(--parcours-transition-easing);
}

.universe:hover .pictureContainer {
  box-shadow: 0 0 0 13px hsl(45deg 90% 80% / 60%);
}
.universe:hover .level {
  color: hsl(45deg 90% 10%);
  text-shadow: 0 0 1px #d8605a, 0 0 3px hsl(45deg 90% 70% / 80%);
}

.universe:hover .level:hover {
  cursor: pointer;
  color: var(--parcours-red);
  text-shadow: 0 0 3px hsl(45deg 90% 70% / 60%);
}
