:root {
  --parcours-grey-600: #2c2e35;
  --parcours-grey-500: #60626b;
  --parcours-grey-400: #707176;
  --parcours-grey-300: #97989d;
  --parcours-grey-200: #d9d9d9;
  --parcours-grey-100: #f9f9f9;
  --parcours-grey-000: #fff;
  --parcours-red: #cc0900;

  --parcours-primary: var(--parcours-red);
  --parcours-secondary: var(--parcours-grey-600);

  --parcours-title-font: Montserrat, sans-serif;
  --parcours-content-font: "Roboto Condensed", sans-serif;

  --parcours-radius-100: 4px;
  --parcours-radius-200: 8px;
  --parcours-radius-300: 14px;
  --parcours-radius-400: 20px;

  --parcours-transition-easing: cubic-bezier(0.4, 0, 0.2, 1);
}

.outer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: white;
  font-family: var(--parcours-content-font);
}
.inner {
  position: relative;
  z-index: 2;
  width: fit-content;
  height: fit-content;
  transform-origin: 0 0;
  will-change: transform;
  user-select: none;
  opacity: 0;
  transition: transform 0ms var(--parcours-transition-easing);
}
.name {
  position: absolute;
  left: 0;
  z-index: 1;
  padding: 10px;
  font-family: var(--parcours-title-font);
  font-size: 46px;
  line-height: 1;
  font-weight: 700;
  color: var(--parcours-red);
}
.outer *,
.outer *::before,
.outer *::after {
  box-sizing: border-box;
}

/* @media only screen and (orientation: portrait) and (hover: none) and (pointer: coarse) {
  .inner {
    transform: rotate(90deg);
  }
} */
