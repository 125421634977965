.emptyStep {
  position: absolute;
  width: 6px;
  height: 6px;
}

.inner {
  width: 100%;
  height: 100%;
}
