.entryPoint {
  position: absolute;
  text-align: center;
  width: 225px;
  /* height: 100px; */
  color: var(--parcours-grey-400);
  background-color: white;
  font-size: 36px;
}
.entryPoint sub {
  display: block;
  font-size: 20px;
}
