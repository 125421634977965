.list {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 34px;
  height: 34px;
  background-color: var(--parcours-red);
  border: 4px solid var(--parcours-grey-600);
  border-radius: 50%;
}

.levels {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.levels.reversed {
  bottom: initial;
  top: calc(100% + 10px);
}

.level {
  color: var(--parcours-red);
  font-size: 30px;
  font-family: var(--parcours-title-font);
  white-space: nowrap;
  font-weight: 700;
}
